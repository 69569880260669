@font-face {
  font-family: "Manrope";
  src: url("/fonts/manrope-regular.woff2"), format("woff2"),
    url("/fonts/manrope-regular.woff"), format("woff");
}

@font-face {
  font-family: "Noto Sans";
  src: url("/fonts/NotoSans-Regular.ttf"), format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  src: url("/fonts/NotoSans-Bold.ttf"), format("truetype");
  font-weight: bold;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  max-width: 100vw;
  width: 100%;
  box-sizing: border-box;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
